import axios from 'axios';

const tokenFace = 'EAACFmqZBPxooBO5hV2sw2Ivjxt8nBlfajmxO1tvjBHcoED9K96mJTm7LQWS4Lz7fmFJrrBBjIdYYzY7cauiITblDvZAC2eyGNmTfmLnDejlBakO7VVHhcFgpGZCBWBq1npvobgGd36sFZAk1K02Ft2hpJIL8BCabDB6qrzUAl0jtCPzU1xwN80mGlEm1juAXNjz1AkcHgaOI2wgZD';
const tokenInsta = 'IGQWRQVG90MmEzTkhraHVEVXpmWlpPSE1leWlkQ0lvVFc1MVJfVzlsYjdfa2k2c0dzOGNvSFNnNWYyQWdPSjBULVlRUktpc3IwYTdRQ3MxaVpXRjJJY3hxUTJVX2kydmhXZAXN4bGlTdllLTXZAZAdmZAFSHN2ZAmxJODQZD';

export const apiFacebook = axios.create({
  baseURL: 'https://graph.facebook.com/v9.0/me?fields=id%2Cname%2Clive_videos%7Bid%2Ctitle%2Cdescription%2Cstatus%2Cstream_url%2Csecure_stream_url%2Cembed_html%2Cvideo%7D&access_token='+tokenFace
});

export const apiInstagram = axios.create({
  baseURL: 'https://graph.instagram.com/me/media?fields=id,username,timestamp,media_type,caption,media_url,thumbnail_url,permalink&access_token='+tokenInsta
});

import React, { useState, useEffect } from 'react';
import './style.css';

import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';

import Loader from '../../componentes/Loarder';
import {apiFacebook, apiInstagram} from '../../services/api';
import NoticiaDetalhes from '../NoticiaDetalhes';

export default function Noticias() {

  const [loade, setloade] = useState(true);
  const [datainstagram, setdatainstagram] = useState([]);

  const [openDetalhesPost, setopenDetalhesPost] = useState(false);
  const [detalhesPost, setdetalhesPost] = useState([]);

  function closeModalDetalhesPost(){
    setopenDetalhesPost(false);
  }
  function handleDetalhesPost(description, image, data){

    setdetalhesPost({
      description: description,
      image: image,
      data: data
    })
    setopenDetalhesPost(true)
  }

  async function getData(){
    let returndatainstagram = await apiInstagram.get('');
    setdatainstagram(returndatainstagram.data);
  }

  useEffect(async()=>{
    await getData();
    setloade(false);
  }, []);


  if(loade){
    return <Loader/>
  }else{
    return (
      <>
        { 
          openDetalhesPost &&
          <NoticiaDetalhes close={closeModalDetalhesPost} detalhes={detalhesPost}/>
        }
        <Header activeMenu="noticias" />
          <div className="container-page container-page-noticias">
            <div className="container-postagens">
              <div className="box-title">
                  <h2>Notícias</h2>
              </div>

              <div className="section">
                {
                  datainstagram?.data?.map( (v, k) =>{
                    if(v.media_type === 'IMAGE' ){

                      let data = v.timestamp.replace(/T/, ' ');
                      data.split("-");
                      data = data[8]+data[9]+"/"+data[5]+data[6]+"/"+data[0]+data[1]+data[2]+data[3];

                      return (
                        <div key={k} className="container-post" onClick={()=>{ handleDetalhesPost(v.caption, v.media_url, data) }}>
                            <div className="box-img">
                            <img src={v.media_url} alt="" />
                            </div>
                            <span>{data}</span>
                            <p>{v.caption}</p>
                        </div>
                      )
                    }
                  })
                }
                
              </div>
            </div>          
          </div>
        <Footer />
      </>
    );
  }
}